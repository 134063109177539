import * as CadastrosApi from './cadastros/cadastros-api'

import { getReq, putReq, postReq } from "@services/api"

export const client = {
    getById: async (id) => { return await getReq('cliente', `/cliente/${id}`, true) },
    update: async (id, body) => { return await putReq('cliente', `/cliente/completar-cadastro/${id}`, body, true) },
    getOperators: async (clienteId) => { return await getReq('operadoras', `/cliente/operadoras/${clienteId}`, true) },
    getByClientId: async (id) => { return await getReq('cliente', `/cliente/operadoras/${id}`, true) },
    locais_select: async () => { return await getReq('cliente', `/cliente/locais`, true) },
    locais: async (nome) => { return await getReq('cliente', `/cliente/locais/${nome}`, true) },
    getPendencies: async (clientId) => { return await getReq('pendências', `/cadastros/cadastro-completo/${clientId}`, true) },
    getApiKeys: async (clientId) => { return await getReq('chaves de api', `/cliente/api-keys/${clientId}`, true) },
    getDadosHome: async (clientId) => { return await getReq('home', `/cliente/home/${clientId}`, true) },
    pontosAtendimento: async (unidadeId) => { return await getReq('cliente', `/cliente/pontos-atendimento/${unidadeId}`, true) },
    pontosAtendimentoAtivos: async (unidadeId) => { return await getReq('cliente', `/cliente/pontos-atendimento-ativos/${unidadeId}`, true) },
    graficos_home: async (clientId) => { return await getReq('home', `/cliente/home-new/${clientId}`, true) },
    user_data: async (email) => { return await getReq('cliente', `/cliente/dados-usuario/${email}`, true) },
    validateSSO: async (clientId) => { return await getReq('cliente', `/cliente/valida-sso-cliente/${clientId}`, true) },
    registerSSO: async (body) => { return await postReq('cliente', '/cliente/sso-cliente', body, true) },
    updateSSO: async (body, clientId) => { return await putReq('cliente', `/cliente/sso-cliente/${clientId}`, body, true) },
    getSSO: async (clientId) => { return await getReq('cliente', `/cliente/sso-cliente/${clientId}`, true) },
    toggleSituationSSO: async (clientId, bool) => { return await getReq('cliente', `/cliente/sso-cliente/${clientId}/${bool}`, true) },
    sistemas_integracao: async () => { return await getReq('cliente', `/cliente/sistemas-integracao`, true) },
    cliente_integracao: async (clientId) => { return await getReq('cliente', `/cliente/cliente-integracao/${clientId}`, true) },
    criar_integracao: async (body) => { return await postReq('cliente', `/cliente/cliente-integracao`, body, true) },
    editar_integracao: async (body, clientId) => { return await putReq('cliente', `/cliente/cliente-integracao/${clientId}`, body, true) },
    toggleIntegration: async (clientId, bool) => { return await getReq('cliente', `/cliente/cliente-integracao/${clientId}/${bool}`, true) }
}

export const perfil = {
    update_password_perfil: async (body) => { return await postReq('senha perfil', `/auth/alterar-senha-perfil`, body, true) }
}

export const gerenciamento = {
    getItensTabela: async (query) => { return await getReq('gerenciamento', `/gerenciamento/listar-itens-tabelas?query=${query}`, true) },
    getEstablishment: async (cnpj) => { return await getReq('estabelecimentos', `/gerenciamento/estabelecimento?cnpj=${cnpj}`, true) },
    getOperators: async (operadora, pageNumber, pageSize) => { return await getReq('operadoras', `/gerenciamento/operadoras?Operadora=${operadora}&PageNumber=${pageNumber}&PageSize=${pageSize}`, true) },
    obterOperadorasSemPendencias: async (operadora, pageNumber, pageSize) => { return await getReq('operadoras', `/gerenciamento/operadoras-sem-pendencias?Operadora=${operadora}&PageNumber=${pageNumber}&PageSize=${pageSize}`, true) },
    getCBOS: async (description, pageNumber, pageSize) => { return await getReq('cbos', `/gerenciamento/cbos?PageNumber=${pageNumber}${description.length > 0 ? `&Busca=${description}` : ''}&PageSize=${pageSize}`, true) },
    getSpecialties: async (specialty) => { return await getReq('especialidades', `/gerenciamento/especialidades${specialty ? `?especialidade=${specialty}` : ''}`, true) },
    getProcedures: async (procedure) => { return await getReq('procedimentos', `/gerenciamento/procedimentos${procedure ? `?procedimento=${procedure}` : ''}`, true) },
    getProfiles: async () => { return await getReq('perfis', `/gerenciamento/perfis`, true) },
    getCities: async (cidade, pageNumber, pageSize) => { return await getReq('cidades', `/gerenciamento/cidades?NomeCidade=${cidade}&PageNumber=${pageNumber}&PageSize=${pageSize}`, true) },
    getCouncils: async (busca, pageNumber, pageSize) => { return await getReq('conselhos', `/gerenciamento/conselhos?PageNumber=${pageNumber}&PageSize=${pageSize}${busca?.length > 0 ? `&Busca=${busca}` : ''}`, true) },
    getConvenios: async (convenio) => { return await getReq('convenios', `/gerenciamento/convenios${convenio ? `?nomeCodigo=${convenio}` : ''}`, true) },
    especialidades_principais: async () => { return await getReq('especialidades', `/gerenciamento/especialidades-principais`, true) },
    procedimentos_por_nome_codigo: async (query, tabela, credencialId) => { return await getReq('procedimentos', `/gerenciamento/procedimentos-autorizacao?query=${query}&tabela=${tabela}&credencialId=${credencialId}`, true) },
    procedimentos_por_nome_codigo_especial: async (busca, pageNumber, pageSize) => { return await getReq('procedimentos', `/gerenciamento/procedimentos-pardini?PageNumber=${pageNumber}&PageSize=${pageSize}${busca?.length > 0 ? `&Busca=${busca}` : ''}`, true) },
    tipos_atendimentos: async () => { return await getReq('gerenciamento', `/gerenciamento/tipos-atendimento`, true) },
    listar_matemad: async (busca, pageNumber, pageSize) => { return await getReq('materiais', `/gerenciamento/mat-meds?PageNumber=${pageNumber}&PageSize=${pageSize}${busca?.length > 0 ? `&Busca=${busca}` : ''}`, true) },
    tabelas_tiss: async () => { return await getReq('tiss', `/gerenciamento/tabelas-tiss`, true) },
    getEstabelecimentoCnes: async (cnpj) => { return await getReq('estabelecimentos', `/gerenciamento/dados-por-cnpj/${cnpj}`, true) },
    checar_portal: async (id) => { return await getReq('gerenciamento', `/gerenciamento/possui-portal/${id}`, true) },
    consultarDadosCFM: async (body) => { return await postReq('gerenciamento', `/gerenciamento/consultar-crm`, body, true) },
    pendencias: async (body) => { return await postReq('pendencias', `/gerenciamento/pendencias`, body, true) },
    pendencias_report: async (tipo, body) => { return await postReq('pendencias', `/gerenciamento/pendencias-report/${tipo}`, body, true) }
}

export const autorizar = {
    alterar_visualizacao: async (guiaId) => { return await getReq('autorizar', `/autorizar/alterar-visualizacao/${guiaId}`, true) },
    autorizacao: async (guiaId) => { return await getReq('autorizar', `/autorizar/autorizacao/${guiaId}`, true) },
    autorizacao_anexo: async (caminhoStorage, guiaId) => { return await getReq('autorizar', `/autorizar/autorizacao/anexo/${caminhoStorage}/${guiaId}`, true) },
    autorizacao_info: async (body) => { return await postReq('autorizar', '/autorizar/autorizacao-info', body, true, 'v2') },
    autorizacoes: async (clientId, search, pageNumber, pageSize) => { return await getReq('autorizar', `/autorizar/autorizacoes?ClienteId=${clientId}${search.length > 0 ? `&Busca=${search}` : ''}&PageNumber=${pageNumber}&PageSize=${pageSize}`, true) },
    cancelar_autorizacao: async (body) => { return await postReq('autorizar', `/autorizar/cancelamento-autorizacao`, body, true) },
    cancelamento_pendente: async (body) => { return await postReq('autorizar', `/autorizar/cancelamento-autorizacao-pendente`, body, true) },
    cancelamento_via_ticket: async (body) => { return await postReq('autorizar', '/autorizar/cancelamento-via-ticket', body, true) },
    confirmar_elegibilidade_biometria: async (credencialId, body) => { return await postReq('autorizar', `/autorizar/confirmar-elegibilidade-biometria/${credencialId}`, body, true) },
    correlacao_procedimentos: async (body) => { return await postReq('autorizar', `/autorizar/correlacao-procedimentos`, body, true) },
    detalhe_pedido: async (id) => { return await getReq('autorizar', `/autorizar/detalhe-pedido/${id}`, true) },
    data_envio_token: async (tokenId) => { return await getReq('autorizar', `/autorizar/data-envio-token/${tokenId}`, true) },
    elegibilidade_biometria: async (credencialId, body) => { return await postReq('autorizar', `/autorizar/elegibilidade-biometria/${credencialId}`, body, true) },
    enviar_pedido: async (body) => { return await postReq('autorizar', '/autorizar/send-pedido', body, true) },
    execucao: async (body) => { return await postReq('autorizar', '/autorizar/execucao', body, true) }, 
    execucao_contatos: async (credencialId, pedido) => { return await getReq('autorizar', `/autorizar/execucao-contatos/${credencialId}/${pedido}`, true, 'v2') },
    execucao_procedimentos_justificativa: async (body) => { return await postReq('autorizar', '/autorizar/execucao-procedimentos-justificativa', body, true, 'v2') },
    execucao_procedimentos_sms: async (body) => { return await postReq('autorizar', '/autorizar/execucao-procedimentos-sms', body, true, 'v2') },
    execucao_token: async (body) => { return await postReq('autorizar', '/autorizar/execucao-token', body, true, 'v2') },
    executar_senha: async (body) => { return await postReq('autorizar', '/autorizar/executar-senha', body, true) },
    executar_guia: async (body) => { return await postReq('autorizar', '/autorizar/executar-procedimentos', body, true) },
    filtrar_historico: async (body) => { return await postReq('autorizar', `/autorizar/filtrar-historico`, body, true) },
    guias_visualizadas: async (clientId) => { return await getReq('autorizar', `/autorizar/marcar-todas-visualizadas/${clientId}`, true) },
    listar_pedidos: async (clientId, search, pageNumber, pageSize) => { return await getReq('autorizar', `/autorizar/pedidos?ClienteId=${clientId}${search.length > 0 ? `&Busca=${search}` : ''}&PageNumber=${pageNumber}&PageSize=${pageSize}`, true) },
    obter_arquivo_report: async (tipo, body) => { return await postReq('autorizar', `/autorizar/report-historico/${tipo}`, body, true) },
    obter_sessao: async (credencialId) => { return await getReq('autorizar', `/autorizar/obter-sessao/${credencialId}`, true) },
    obter_usuario_guia: async (id) => { return await getReq('autorizar', `/autorizar/obter-usuario-guia/${id}`, true) },
    obter_usuario_pendencia: async (senha) => { return await getReq('autorizar', `/autorizar/obter-usuario-pendencia/${senha}`, true) },
    paciente_biometria: async (operadoraId, clienteId, numeroCarteira) => { return await getReq('autorizar', `/autorizar/paciente-biometria/${operadoraId}/${clienteId}/${numeroCarteira}`, true) },
    pedido_revisado: async (id) => { return await getReq('autorizar', `/autorizar/pedido-revisado/${id}`, true) },
    pesquisa_beneficiario: async (body) => { return await postReq('autorizar', `/autorizar/pesquisa-beneficiario`, body, true) },
    print_pdf_autorizacao: async (id) => { return await getReq('autorizar', `/autorizar/pdf-autorizacao/${id}`, true) },
    procedimentos_execucao: async (credencialId, codigoPedido) => { return await getReq('autorizar', `/autorizar/procedimentos-execucao/${credencialId}/${codigoPedido}`, true) },
    recebimento_imagens: async (body) => { return await postReq('autorizar', '/autorizar/recebimento-imagens', body, true) },
    reprocessar_anexo: async (body) => { return await postReq('autorizar', `/autorizar/reprocessar-anexo`, body, true) },
    reprocessar_anexo_provider: async (body) => { return await postReq('autorizar', `/autorizar/reprocessar-anexo-provider`, body, true) },
    salvar_beneficiario_elegibilidade: async (clienteId, operadoraId, body) => { return await postReq('autorizar', `/autorizar/salvar-beneficiario-elegibilidade/${clienteId}/${operadoraId}`, body, true) },
    solicitacoes_execucao: async (body) => { return await postReq('autorizar', `/autorizar/solicitacoes-execucao`, body, true) },
    spending_ceiling_ipsemg: async (body) => { return await postReq('autorizar', '/autorizar/ipsemg-spending-ceiling', body, true) },
    spsadt: async (body) => { return await postReq('autorizar', `/autorizar/spsadt`, body, true) },
    teto_iasep: async (body) => { return await postReq('autorizar', '/autorizar/teto-iasep', body, true, 'v2') },
    token_pedido: async (body) => { return await postReq('autorizar', `/autorizar/token-pedido`, body, true) },
    transcricao_exames: async (body) => { return await postReq('autorizar', `/autorizar/transcricao-exames`, body, true) },
    validar_elegibilidade: async (body) => { return await postReq('autorizar', `/autorizar/elegibilidade`, body, true) },
    validar_pendencias: async (body) => { return await postReq('autorizar', '/autorizar/validar-pendencias', body, true, "v2") },
    validar_token_pedido: async (id) => { return await getReq('autorizar', `/autorizar/validar-token-pedido/${id}`) }
}

export const credenciais = {
    addProcTabelaPropria: async (body) => { return await postReq('credenciais', `/credenciais/procedimento-tabela-propria`, body, true, 'v2') },
    ativarDesativarProcedimentoTabelaPropria: async (id, bool) => { return await getReq('credenciais', `/credenciais/${id}/${bool}`, true, 'v2') },
    atualizar: async (credencialId, body) => { return await putReq('credenciais', `/credenciais/${credencialId}`, body, true, 'v2') },
    atualizarSituacao: async (credencialId, bool) => { return await putReq('credenciais', `/credenciais/${credencialId}/situacao/${bool}`, {}, true, 'v2') },
    credenciais_autorizacao: async (body) => { return await postReq('credenciais', `/credenciais/credenciais-autorizacao`, body, true, 'v2') },
    getById: async (credencialId) => { return await getReq('credenciais', `/credenciais/${credencialId}`, true, 'v2') },
    getTabelaPropria: async (credencialId, search, pageNumber, pageSize) => { return await getReq('credenciais', `/credenciais/procedimento-tabela-propria?CredencialId=${credencialId}${search.length ? `&Busca=${search}` : ''}&PageNumber=${pageNumber}&PageSize=${pageSize}`, true, 'v2') },
    listar: async (search, situacao, pageNumber, pageSize) => { return await getReq('credenciais', `/credenciais?${search.length ? `Busca=${search}` : ''}&Situacao=${situacao}&PageNumber=${pageNumber}&PageSize=${pageSize}`, true, 'v2') },
    salvar: async (body) => { return await postReq('credenciais', '/credenciais', body, true, 'v2') }
}

export const tipos_credenciais = {
    listar_por_operadora_id: async (operadoraId, pageNumber, pageSize) => { return getReq('tipos_credenciais', `/tipos-credenciais?OperadoraId=${operadoraId}&PageNumber=${pageNumber}&PageSize=${pageSize}`, true, 'v2') }
}

export const configuracoes = {
    monitor: async (startDate, endDate, pageNumber, pageSize) => { return await getReq('configuracoes', `/configuracoes/monitoramento?StartDate=${startDate}&EndDate=${endDate}&PageNumber=${pageNumber}&PageSize=${pageSize}`, true) }
}

export const relatorios = {
    procedimentos_emitidos: async (body) => { return await postReq('relatorios', `/relatorios/procedimentos-emitidos`, body, true, 'v2') },
    colaboradores_logados: async (body) => { return await postReq('relatorios', `/relatorios/colaboradores-logados`, body, true, 'v2') },
    unidades_logadas: async (body) => { return await postReq('relatorios', `/relatorios/unidades-logadas`, body, true, 'v2') },
    acessos_colaboradores: async (body) => { return await postReq('relatorios', `/relatorios/acessos-colaboradores`, body, true, 'v2') },
    procedimentos_emitidos_conveio: async (body) => { return await postReq('relatorios', `/relatorios/procedimentos-emitidos-conveio`, body, true, 'v2') },
    procedimentos_emitidos_unidade: async (body) => { return await postReq('relatorios', `/relatorios/procedimentos-emitidos-unidade`, body, true, 'v2') },
    download: async (body) => { return await postReq('relatorios', `/relatorios/download`, body, true, 'v2') }
}

export const cadastros = { ...CadastrosApi }